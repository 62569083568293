import './Cusform.css';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer/index';
import firebase, { child, database, get, useFirebase, databaseurl } from '../Login/config';
import Header from '../Header/index';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { useNavigate } from 'react-router-dom';

export default function Rate() {
  const UserId = localStorage.getItem("AccessUserId");
  const [companydata, setCompany] = useState([]);
  const [CompanyId, setCompanyId] = useState("");
  const [companyData, setCompanyData] = useState(null);
  const [open, setOpen] = useState(false);
  const [items, setitems] = useState([{ item: "", dse: "", qty: "", price: "", amt: "" }]);
  const [userdata, SetUserdata] = useState({
    customer: "",
    email: "",
    cus_invoice_number: "",
    address: "",
    dueDate: "",
    cus_city: "",
    customerGSTNo: "",
    phone: "",
    note: "",
  });
  const [errors, setErrors] = useState({});
  const [Taxs, setTax] = useState('');
  const [taxvalue, settaxvalue] = useState("");
  const [discount, setdiscount] = useState("");
  let total = 0;

  const navigate = useNavigate();
  const UserID = localStorage.getItem("AccessUserId");
  const venderId = Math.floor(Math.random() * 9000000000000) + 1;
  const companyId = CompanyId !== "undefined" ? CompanyId : venderId + 1500920;
  const invoiceId = Date.now();
  const date = new Date();
  const creationDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'long' })}, ${date.getFullYear()}`;

  // Fetch company data
  const getcompany = () => {
    get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const companies = Object.entries(data.company);
        setCompany(companies);

        for (let index = 0; index < companies.length; index++) {
          setCompanyId(companies[index][1].companyId);
        }
      }
    }).catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    getcompany();
  }, [CompanyId]);

  // Handle tax and discount logic
  const TaxType = () => {
    let CurrencyCode = localStorage.getItem("currencyCode");
    if (["EUR", "CZK", "DKK", "HUF", "PLN", "RON", "SEK", "GBP"].includes(CurrencyCode)) {
      setTax("VAT");
    } else if (CurrencyCode === "INR") {
      setTax("GST");
    } else {
      setTax("TAX");
    }
  };

  useEffect(() => {
    TaxType();
  }, [Taxs]);

  const handleClick = (value) => {
    settaxvalue(value);
  };

  const handleClicks = (value) => {
    setdiscount(value);
  };

  const postUserData = (e) => {
    SetUserdata({ ...userdata, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!userdata.customer) {
      newErrors.customer = 'Customer Name is required';
      isValid = false;
    }
    if (!items[0].item) {
      newErrors.item = 'Item Name is required';
      isValid = false;
    }
    if (!items[0].qty) {
      newErrors.qty = 'Quantity is required';
      isValid = false;
    }
    if (!items[0].price) {
      newErrors.price = 'Rate is required';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  // Handle add item
  const add_iteam = () => setitems([...items, { item: "", dse: "", qty: "", price: "", amt: "" }]);

  // Handle remove item
  const remove_iteam = (index) => {
    const list = [...items];
    list.splice(index, 1);
    setitems(list);
  };

  const submitedata = async (event) => {
    event.preventDefault();
    localStorage.setItem("ProductData", JSON.stringify(items));
    const { customer, email, cus_invoice_number, dueDate, address, customerGSTNo, phone, note } = userdata;

    // Calculate GST and Discount
    const Growprice = parseInt(localStorage.getItem("total"));
    const GST = (Growprice * taxvalue / 100);
    const discountAmount = (GST * discount / 100).toFixed(2);
    const totalAmount = (Growprice + GST - discountAmount).toFixed(2);

    if (validateForm()) {
      const res = await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/billInvoice.json`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer, email, address, dueDate, customerGSTNo, phone, items, companyId, invoiceId, total, gstAmount: GST, gstPercetage: taxvalue, discountAmount, discountPercetage: discount, totalAmount, creationDate, note,
        }),
      });

      await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/session/${venderId}.json`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ venderId, creationDate }),
      });

      window.location = "/simple-invoice-template";
    }
  };

  // Handle popup open and set selected company data
  const handleClickOpen = (event, companyId) => {
    setCompanyId(companyId);
    setOpen(true);
  };

  useEffect(() => {
    if (companyId) {
      const companyData = companydata.find(company => company[1].companyId === companyId);
      setCompanyData(companyData ? companyData[1] : null);
    }
  }, [companyId, companydata]);

  return (
    <>
      <Header />
      <div className='Form'>
        {/* Button to open the popup */}
        <button onClick={(event) => handleClickOpen(event, companydata[1]?.companyId)}>
          <i className="fa-regular fa-pen-to-square"></i>
        </button>

        {/* Popup to edit company data */}
        {open && companyData && (
          <div className="popup">
            <form onSubmit={submitedata}>
              <div className='compnay_details'>
                {companyData ? (
                  <div className="bussiness">
                    <div className="Compny">
                      <div className='Compny_info'>
                        <div className="compny_left">
                          <div className='compny_info_details'>
                            <h3>{companyData.companyName}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : "No Records found"}
              </div>

              {/* Customer Details Form */}
              <div className='customer_details'>
                <input
                  type="text"
                  name="customer"
                  value={userdata.customer}
                  placeholder="Customer Name"
                  onChange={postUserData}
                />
                {errors.customer && <p>{errors.customer}</p>}

                <input
                  type="email"
                  name="email"
                  value={userdata.email}
                  placeholder="Customer Email"
                  onChange={postUserData}
                />

                <input
                  type="text"
                  name="cus_invoice_number"
                  value={userdata.cus_invoice_number}
                  placeholder="Invoice Number"
                  onChange={postUserData}
                />

                <input
                  type="text"
                  name="address"
                  value={userdata.address}
                  placeholder="Customer Address"
                  onChange={postUserData}
                />

                <input
                  type="text"
                  name="dueDate"
                  value={userdata.dueDate}
                  placeholder="Due Date"
                  onChange={postUserData}
                />
              </div>

              {/* Items Table */}
              <div className="Bill-item">
                {items.map((item, index) => {
                  total = total + item.amt;
                  localStorage.setItem("total", total);
                  return (
                    <div className='Add-item' key={index}>
                      <div className="Item">
                        <input
                          type="text"
                          name="item"
                          value={item.item}
                          placeholder="Item Name"
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].item = e.target.value;
                            setitems(newItems);
                          }}
                        />
                      </div>

                      <div className="Description">
                        <input
                          type="text"
                          name="dse"
                          value={item.dse}
                          placeholder="Description"
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].dse = e.target.value;
                            setitems(newItems);
                          }}
                        />
                      </div>

                      <div className="Qty">
                        <input
                          type="number"
                          name="qty"
                          value={item.qty}
                          placeholder="Quantity"
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].qty = e.target.value;
                            setitems(newItems);
                          }}
                        />
                        {errors.qty && <p>{errors.qty}</p>}
                      </div>

                      <div className="Price">
                        <input
                          type="number"
                          name="price"
                          value={item.price}
                          placeholder="Price"
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].price = e.target.value;
                            newItems[index].amt = newItems[index].qty * newItems[index].price;
                            setitems(newItems);
                          }}
                        />
                        {errors.price && <p>{errors.price}</p>}
                      </div>

                      <div className="Amt">
                        <input
                          type="text"
                          name="amt"
                          value={item.amt}
                          placeholder="Amount"
                          disabled
                        />
                      </div>

                      {/* Add/Remove Item Logic */}
                      {items.length > 1 && (
                        <button type="button" onClick={() => remove_iteam(index)}>
                          Remove Item
                        </button>
                      )}
                    </div>
                  );
                })}
                <button type="button" onClick={add_iteam}>Add Item</button>
              </div>

              {/* Submit Button */}
              <div className="submit_button">
                <button type="submit">Save</button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
