import { Link } from 'react-router-dom';
import './Comform.css';
import { useEffect, useState } from 'react';
import Footer from '../Footer/index';
import firebase, { database, storage, useFirebase, imgDb, app, databaseurl } from '../Login/config';
import { getDownloadURL, uploadBytes, ref } from 'firebase/storage';
import { v4 } from 'uuid';
import { useForm } from 'react-hook-form';
import { redirect } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Rate() {
  const UserID = localStorage.getItem("AccessUserId");
  const venderId = String(Math.floor(Math.random() * 9000000000000) + 1);
  const companyId = String(Number(venderId) + 1500920);
  const invoiceId = Date.now();
  const date = new Date();
  const timestampSeconds = Math.floor(date.getTime() / 1000);
  const Day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const Year = date.getFullYear();

  const creationDate = String(timestampSeconds);

  const companyPhoto = localStorage.getItem("companyPhoto");

  function handleupload(e) {
    const imgs = ref(imgDb, `invoiceGenrator/users/${UserID}/${v4()}`);
    uploadBytes(imgs, e.target.files[0]).then(data => {
      getDownloadURL(data.ref).then(val => {
        localStorage.setItem("companyPhoto", val);
        
        setFormData((prevFormData) => ({ ...prevFormData, companyPhoto: val }));
        var input = e.target;
        var image = document.getElementById('preview');
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = function (e) {
            image.src = e.target.result;
          }
          reader.readAsDataURL(input.files[0]);
        }
      });
    });
  }

  const [formData, setFormData] = useState({
    companyName: "",
    address: "",
    gstNumber: "",
    terms: "",
    companyPhoto: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.companyName) {
      newErrors.companyName = 'Business Name is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const { companyName, address, gstNumber, terms } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (UserID) {
        await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/company.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyName, companyId, address, gstNumber, terms, companyPhoto, creationDate,
          }),
        });
      } else {
        // Check if form data already exists in localStorage
        const storedBusinessData = localStorage.getItem('business');
        if (!storedBusinessData) {
          // Store form data in local storage
          localStorage.setItem('business', JSON.stringify({
            companyName, companyId, address, gstNumber, terms, companyPhoto, creationDate,
          }));
        }
      }

      // Clear form data
      setFormData({
        companyName: "",
        address: "",
        gstNumber: "",
        terms: "",
      });
      localStorage.removeItem("companyPhoto");

      window.location = "/generate-invoice";
    }
  };

  const [Taxs, setTax] = useState('');
  let Symbol = localStorage.getItem("symbol");
  let CurrencyCode = localStorage.getItem("currencyCode");
  const TaxType = () => {
    if (CurrencyCode === "EUR" || CurrencyCode === "CZK" || CurrencyCode === "DKK" || CurrencyCode === "HUF" ||
      CurrencyCode === "PLN" || CurrencyCode === "RON" || CurrencyCode === "SEK" || CurrencyCode === "GBP") {
      setTax("VAT");
      localStorage.setItem("tax", Taxs);
    } else if (CurrencyCode === "INR" || CurrencyCode === "AUD") {
      setTax("GST");
      localStorage.setItem("tax", Taxs);
    } else {
      setTax("TAX");
      localStorage.setItem("tax", Taxs);
    }
  }

  useEffect(() => {
    TaxType();
  }, [Taxs]);

  const [taxvalue, settaxvalue] = useState("");
  const handleClick = (value) => {
    settaxvalue(value);
  };
  const [discount, setdiscount] = useState("");
  const handleClicks = (value) => {
    setdiscount(value);
  };

  const Customeplaceholder = "Enter" + " " + Taxs;
  return (
    <>
      <Helmet>
        <title>
          List Your Business on BillNama for Instant Invoicing Solutions
        </title>
        <link
          rel="canonical"
          href="https://app.billnama.com/list-your-business"
        />
      </Helmet>
      <div className='Form1'>
        <form onSubmit={handleSubmit}>
          <div className='Com_cus'>
            <div className='compny-info'>
              <h2 ><b><i className="fa-solid fa-circle-half-stroke"></i></b> Your Business </h2>
              <div className='details'>
                <div className='logoprivew'>
                  <img id='preview' />
                </div>
                <div className='choose-file' for="file">
                  <div>
                    <input type='file' id="file" name='companyPhoto' onChange={(e) => handleupload(e)} />
                    <label for="file">Choose Company Logo</label>
                  </div>
                </div>
                <div className='info'>
                  <div className='name_gst'>
                    <div className='companyName'>
                      <label htmlFor="cname">Business name*</label>
                      <input className='input' type='text' id='companyName' name="companyName" value={formData.companyName} onChange={handleChange} placeholder='Enter Name' />
                      {errors.companyName && <span >{"⚠" + " " + errors.companyName}</span>}
                    </div>
                    <div className='gst'>
                      <label>{Taxs}</label>
                      <input id='gstNumber' name="gstNumber" value={formData.gstNumber} onChange={handleChange} placeholder={Customeplaceholder} />
                    </div>
                  </div>
                  <div className='address' type='text'>
                    <label>Address</label>
                    <textarea type='text' id="address" name="address" value={formData.address} onChange={handleChange} placeholder='Enter Address' />
                  </div>
                  <div className='Terms_cond'>
                    <label>T&C / Account Details</label>
                    <textarea type="text" id="terms" name="terms" value={formData.terms} onChange={handleChange} placeholder='Enter Terms and condition / Account Details'> </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='Submit'>
            <button type='submit'> Next</button>
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
}
