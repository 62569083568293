import { Link, json } from 'react-router-dom';
import './New-invoice.css';
import logo from './images/image_2023_04_18T11_21_42_112Z-removebg-preview.png';
import compnylogo from './images/images-removebg-preview (22).png';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import firebase, { database, get, child } from '../Login/config';
import ReactDOM from 'react-dom';
import SignatureCanvas from 'react-signature-canvas';
import Preview from '../previewsidebar/index';
import Loader from '../loader/index';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Helmet } from 'react-helmet';

export default function InvoiceData() {

  const company_ids = localStorage.getItem("company_id");
  const companyName = localStorage.getItem("companyName");
  const address = localStorage.getItem("address");
  const com_emil = localStorage.getItem("com_emil");
  const UserId = localStorage.getItem("AccessUserId");
  const customer = localStorage.getItem("customer");
  const cus_invoice_number = localStorage.getItem("cus_invoice_number");
  const customerGSTNo = localStorage.getItem("customerGSTNo");
  const dueDate = localStorage.getItem("dueDate");
  // const address = localStorage.getItem("address");
  const phone = localStorage.getItem("phone");
  const email = localStorage.getItem("email");
  const cus_note = localStorage.getItem("cus_note");
  const com_terms = localStorage.getItem("com_terms");
  const com_country = localStorage.getItem("com_country")
  const Discount_amount = localStorage.getItem("DiscountAmounts");
  const Logo = localStorage.getItem("companyPhoto");
  var ProductData = JSON.parse(localStorage.getItem("ProductData"));
  const Discount_pr = localStorage.getItem("discount");
  const Tax_pr = localStorage.getItem("taxvalue");
  const Growprice = localStorage.getItem("Growprice");
  const Gst = localStorage.getItem("Gst");
  const totalAmount = localStorage.getItem("totalAmount");
  const terms = localStorage.getItem("terms");
  const symbol = localStorage.getItem("symbol");
  const Tax = localStorage.getItem("tax");




  // Convert image to base64Img code start

  const [base64Image, setBase64Image] = useState('');
  const headers = 'Access-Control-Allow-Credentials, * ';
  const imageUrlFromLocalStorage = localStorage.getItem("Compnylogoimage");
  useEffect(() => {
    const convertLocalStorageImageToBase64 = async () => {
      try {
        // Retrieve the image URL from localStorage
        // const imageUrlFromLocalStorage = localStorage.getItem('imageUrl');
        // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
        if (imageUrlFromLocalStorage) {
          // console.log('imageUrlFromLocalStorage', imageUrlFromLocalStorage)
          const response = await fetch(`${imageUrlFromLocalStorage}`);
          console.log('response', response)

          const blob = await response.blob();
          const base64String = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          setBase64Image(base64String);
          console.log(base64String);
        } else {
          console.error('Image URL not found in localStorage');
        }
      } catch (error) {
        console.error('Error converting image to Base64:', error);
      }
    };

    convertLocalStorageImageToBase64();

  }, []);
  // Convert image to base64Img code end...
  let Showitems;
  let ShowitemsFirebase;

  // pdf print start
  // const inputRef = useRef();
  const genretpdf = useReactToPrint({
    content: () => inputRef.current,
    documentTitle: "Invoice",
  });

  // pdf print start
  const UserName = localStorage.getItem("UserName");

  const inputRef = useRef();
  var data = document.getElementById('divToPrint');
  const printDocument = () => {
    html2canvas(data, { allowTaint: true, useCORS: true, type: 'jpeg', quality: 0.0, scale: 2, }).then((canvas) => {
      // allowTaint: true;
      // useCORS: true;
      const imgData = canvas.toDataURL("image/png");
      // console.log(imgData);
      const pdf = new jsPDF();
      pdf.addImage(imgData, "png", 10, 10, 190, 250);
      pdf.save("Invoice.pdf");
    });
  };
  // pdf print End

  // Start code getdata using firbase.
  const [datas, setData] = useState();
  const [iteamData, setIteamData] = useState();
  const [company, setCompany] = useState();
  let bildata = [];
  let bildatas = [];
  const [isLoading, setIsLoading] = useState(false);

  const getbilinvoice = () => {
    setIsLoading(true);
    get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
      setIsLoading(false);
      if (snapshot.exists()) {

        bildata = snapshot.val().billInvoice;
        const data = snapshot.val();
        setData(Object.entries(data.billInvoice));
        setCompany(Object.entries(data.company));
        setIteamData(Object.entries(data.billInvoice.items));
        // console.log('first', data.billInvoice.items[0].amt)

      }

    }).catch((error) => {
      console.error(error);
    });

  }
  useEffect(() => {
    getbilinvoice();
  }, []);

  const invoice_id = localStorage.getItem("createdInvoiceId");

  function formatDate(timestamp) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let date;

    // Check if input is a Unix timestamp (integer or string)
    if (!isNaN(timestamp)) {
        if (timestamp.toString().length === 10) {
            // If it's 10 digits long, treat it as seconds and convert to milliseconds
            date = new Date(timestamp * 1000);
        } else if (timestamp.toString().length === 13) {
            // If it's 13 digits long, treat it as milliseconds
            date = new Date(parseInt(timestamp, 10));
        }
    } else {
        // Try to parse date string in various formats
        if (/\d{2}-\d{2}-\d{4}/.test(timestamp)) {
            // If format is dd-mm-yyyy
            const [day, month, year] = timestamp.split('-');
            date = new Date(`${year}-${month}-${day}`);
        } else if (/\d{4}-\d{2}-\d{2}/.test(timestamp)) {
            // If format is yyyy-mm-dd
            date = new Date(timestamp);
        } else if (/\d{2}-\d{2}-\d{2}/.test(timestamp)) {
            // If format is dd-mm-yy
            const [day, month, year] = timestamp.split('-');
            const fullYear = `20${year}`; // Assuming it's 2000+
            date = new Date(`${fullYear}-${month}-${day}`);
        } else {
            // Fall back to creating Date from any valid date string
            date = new Date(timestamp);
        }
    }

    if (!date || isNaN(date.getTime())) {
        return 'Invalid Date'; // Handle invalid date inputs
    }

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${months[monthIndex]}, ${year}`;
}

  const handleDownloads = () => {
    const myContentDiv = document.getElementById('divToPrint');
    const textContent = myContentDiv.textContent || myContentDiv.innerText;
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    // Add content to the PDF document
    pdf.text(textContent, 10, 10);

    // Save the PDF with a specific name
    pdf.save('example.pdf');
  };

  // End code getdata using firbase.
  return (
    <>
    <Helmet>
        <title>
        Generate Invoices With Our Modern Templates | Explore Now!
        </title>
        {/* <meta
          name="description"
          content="Explore the Invoice mobile app case study by Softnoesis, a testament to our success in mobile development. Discover how we created a user-friendly invoicing solution, enhanced productivity, and streamlined business operations. Click now to dive into this transformative success story!"
        /> */}
        <link
          rel="canonical"
          href="https://app.billnama.com/modern-invoice-template"
        />
      </Helmet>
      <Preview />
      <Link to="/invoices">
        <div className='back'>
          <i className="fa-sharp fa-solid fa-xmark"></i>
        </div>
      </Link>
      {isLoading ? <Loader /> : UserId &&
        <div className='Invoice7'>
          <div id="gap">
            <h2>helooooooo</h2>
          </div>
          <div className='Invoices7' ref={inputRef} >

            <div className='Invoice-details' id="divToPrint" ref={inputRef}>
              <div className='box_section'>
                <div className='box'>

                </div>
                <div className='box'>

                </div>
                <div className='box'>

                </div>
                <div className='box'>

                </div>
                <div className='box'>

                </div>
              </div>

              {/* Company details code start  */}
              {company ? company.map((company_data, i) => {

                if (company_data[1].companyId == company_ids) {
                  { localStorage.setItem("Compnylogoimage", company_data[1].companyPhoto) }
                  return (
                    <div className='bussiness-info'>
                      <div className='bussiness-info-details'>
                        <h3>{company_data[1].companyName}</h3>
                        <p> <b>{Tax} </b>{company_data[1].gstNumber}</p>
                        <p className='adress'> {company_data[1].address}</p>
                      </div>
                      <div className='bussiness-info-logo'>
                        <img src={imageUrlFromLocalStorage} />
                      </div>
                    </div>
                  )
                }
              }) : "Company Data Not Found"}
              {/* Company details code End  */}
              {/* customer-info start */}

              {datas ? datas.map((invo_data, index) => {
                if (invoice_id == invo_data[1].invoiceId) {
                  return (
                    <>
                      <div className="customer-info" key={index}>
                        <div className='Cusname-invoice-number'>
                          <div className='Cus-name'>
                            <span>To, <span>{invo_data[1].customer}</span></span>
                          </div>
                          <div className='invoice-number'>
                            {/* <span>Invoice: <span>{invo_data[1].cus_invoice_number}</span></span> */}
                          </div>
                        </div>
                        <div className='Gst-date'>
                          <div className='Gst'>
                            <span>{Tax} <span>{invo_data[1].customerGSTNo}</span></span>
                          </div>
                          <div className='Date'>
                            <span>Date: <span>{formatDate(invo_data[1].date)}</span></span><br />
                            <span>Due date: <span>{formatDate(invo_data[1].dueDate)}</span></span>
                          </div>
                        </div>
                        <div className='Adress'>
                          <p>{invo_data[1].address}</p>
                        </div>
                        <div className='number'>
                          <span><span>{invo_data[1].phone}</span></span>
                        </div>
                        <div className='Email'>
                          <span><span>{invo_data[1].email}</span></span>
                        </div>
                      </div>
                      <div className='purshcing-info'>
                        <div className='purshcing-info-heading'>
                          <div className='no-item'>
                            <div className='no'>
                              <p>Item</p>
                            </div>
                            {/* <div className='item'>
                              <p>Description</p>
                            </div> */}
                          </div>

                          <div className='price-amount'>
                            <div className='no'>
                              <p>Qty</p>
                            </div>
                            <div className='item'>
                              <p>Price</p>
                            </div>
                            <div className='item'>
                              <p>Amount</p>
                            </div>
                          </div>
                        </div>
                        {/* purshcing-info-section1 Start*/}


                        <ol type=''>
                          {invo_data[1].items.map((items, i) => {
                            return (
                              <li>
                                <div className='purshcing-info-section1'>
                                  <div className='no-item'>
                                    <div className='no'>
                                      <p>{items.name}</p>
                                    </div>

                                  </div>
                                  <div className='price-amount'>
                                    <div className='Qty'>
                                      <p>{items.quantity}</p>
                                    </div>
                                    <div className='Price'>
                                      <span>{symbol}<span>{items.price}</span></span>
                                    </div>
                                    <div className='Amount'>
                                      <span>{symbol}<span>{items.amount}</span></span>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className='items'>
                                      <p>{items.dse}</p>
                                </div> */}
                              </li>
                            )
                          })
                          }
                        </ol>
                        {/* purshcing-info-section1  End*/}

                        {/* purshcing-info-section3 Start*/}
                        <div className='iteam-total'>
                          <div className='total-Amount_left'>
                            <p>Invoice Total</p>
                            <span>{symbol}<span>{invo_data[1].totalAmount}</span></span>
                          </div>
                          <div className='total-Amount'>
                            <span>{symbol}<span>{invo_data[1].total}</span></span>
                          </div>
                        </div>

                        {/* purshcing-info-section3  End*/}
                        <div>
                          <div className='Sub-Gst'>
                            <div className='CGST'>
                              <span>{Tax} (<span>{invo_data[1].gstPercetage + "%"} </span>) :</span>
                            </div>
                            <div className='CGST-price'>
                              <span><span>{invo_data[1].gstAmount}</span></span>
                            </div>
                          </div>
                          <div className='Sub-Gst'>
                            <div className='Disc'>
                              <span>Disc (<span>{invo_data[1].discountPercetage + "%"}</span>)  :</span>
                            </div>
                            <div className='Disc-price'>
                              <span>{symbol}<span>{invo_data[1].discountAmount}</span></span>
                            </div>
                          </div>
                          <div className='Sub-Gst'>
                            <div className='Net-amount'>
                              <span><b>Payable Amount</b></span>
                            </div>
                            <div className='Net-price'>
                              <span><b>{symbol}<span>{invo_data[1].totalAmount}</span></b></span>
                            </div>
                          </div>
                        </div>
                        <div className='Note'>
                          <span><b>Note: </b></span>
                          <span>{invo_data[1].note} </span>
                        </div>
                        <div className="signeture-mens">
                          <div className='Mens'>
                            <p>Men's</p>
                          </div>
                          <div className='signature'>
                            <div className='sig-box'>
                            </div>
                            <p>Signature of Company</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
              }) : "testdata"}

              {/* customer-info Code End */}
              <div className='Tearms-section'>
                <div className='Tearms'>
                  <span>Terms:</span>
                  {company ? company.map((company_data, i) => {
                    if (i === company.length - 1) {
                      return (
                        <div className='bussiness-info'>
                          <div className='bussiness-info-details'>
                            <p>{company_data[1].terms}</p>

                          </div>
                        </div>
                      )
                    }
                  }) : "Company Data Not Found"}
                </div>
                <div className='Reciver-sig'>
                  {/* <div className='signature'>
                    <div className='sig-box'>
                    </div>
                    <p>Receiver Signature</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        ||
        <div className='Invoice1'>
          <div className='Invoices1' id="divToPrint" ref={inputRef}>
            <Link to="/">
              <div className='back'>
                <i className="fa-sharp fa-solid fa-xmark"></i>
              </div>
            </Link>

            <div className='Invoice-details'>

              <div className='bussiness-info'>

                <div className='bussiness-info-logo'>
                  <img src={Logo} />
                </div>
                <div className='bussiness-info-details'>
                  <h3>{companyName}</h3>
                  <p>{com_country}</p>
                  <p> {com_emil}</p>
                  <p className='adress'><b>Address:</b> {address}</p>

                </div>
              </div>


              <div className="customer-info">{/* customer-info start */}
                <div className='Cusname-invoice-number'>
                  <div className='Cus-name'>
                    <span>  To, <span>{customer}</span></span>
                  </div>
                  <div className='invoice-number'>
                    <span>Invoice:<span>{cus_invoice_number}</span></span>
                  </div>
                </div>

                <div className='Gst-date'>
                  <div className='Gst'>
                    <span>{Tax} <span>{customerGSTNo}</span></span>
                  </div>
                  <div className='Date'>
                    <span>Date:<span>{dueDate}</span></span>
                  </div>
                </div>

                <div className='Adress'>
                  <p>{address}</p>
                </div>

                <div className='number'>
                  <span>MO: <span>{phone}</span></span>
                </div>

                <div className='Email'>
                  <span>Email: <span>{email}</span></span>
                </div>


              </div>{/* customer-info End */}

              <div className='purshcing-info'>
                <div className='purshcing-info-heading'>
                  <div className='no-item'>
                    <div className='no'>
                      <p>Item</p>
                    </div>
                    <div className='item'>
                      <p>Description</p>
                    </div>
                  </div>

                  <div className='price-amount'>
                    <div className='no'>
                      <p>Qty</p>
                    </div>
                    <div className='item'>
                      <p>Price</p>
                    </div>
                    <div className='item'>
                      <p>Amount</p>
                    </div>
                  </div>
                </div>

                {/* purshcing-info-section1 Start*/}
                {Showitems = ProductData.map((element) => {
                  return (<ol>
                    <li>
                      <div className='purshcing-info-section1'>
                        <div className='no-item'>
                          <div className='no'>
                            <p>{element.item}</p>
                          </div>
                          <div className='item'>
                            <p>{element.dse}</p>
                          </div>
                        </div>
                        <div className='price-amount'>
                          <div className='Qty'>
                            <p>{element.qty}</p>
                          </div>
                          <div className='Price'>
                            <span>{symbol}<span>{element.price}</span></span>
                          </div>
                          <div className='Amount'>
                            <span>{symbol}<span>{element.amt}</span></span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ol>)
                })}

                {/* purshcing-info-section1  End*/}

                {/* divider start */}
                <div className='divider'>
                  <hr />
                </div>
                {/* divider End */}

                {/* purshcing-info-section3 Start*/}
                <div className='iteam-total'>
                  <div>
                    <span>{symbol}<span>{Growprice}</span></span>
                  </div>
                  <div className='total-Amount'>
                    <span>{symbol}<span>{Growprice}</span></span>
                  </div>
                </div>
                {/* purshcing-info-section3  End*/}

                {/* divider start */}

                {/* divider End */}
                <div>
                  <div className='Sub-Gst'>
                    <div className='CGST'>
                      <span>{Tax} <span> ({Tax_pr}%) :</span></span>
                    </div>
                    <div className='CGST-price'>
                      <span><span>{Gst}</span></span>
                    </div>
                  </div>
                  {/* <div className='Sub-Gst'>
              <div className='SGST'>
                  <span>SGST<span>(2.5%) :</span></span>
              </div>
              <div className='SGST-price'>
                <span>{symbol}<span>100</span></span>
              </div>
          </div> */}
                  <div className='Sub-Gst'>
                    <div className='Disc'>
                      <span>Disc <span> ({Discount_pr}%): </span></span>
                    </div>
                    <div className='Disc-price'>
                      <span><span>{Discount_amount}</span></span>

                    </div>
                  </div>
                  <div className='Sub-Gst'>
                    <div className='Net-amount'>
                      <span><b>Payable Amount</b></span>
                    </div>
                    <div className='Net-price'>
                      <span><b><span>{totalAmount}</span></b></span>
                    </div>
                  </div>
                </div>
                <div className='Note'>
                  <span><b>Note: </b></span>
                  <span>{cus_note} </span>
                </div>
                <div className="signeture-mens">
                  <div className='Mens'>
                    <p>Men's</p>
                  </div>
                  <div className='signature'>
                    <div className='sig-box'>

                    </div>
                    <p>Signature of Company</p>
                  </div>
                </div>

              </div>

              <div className='Tearms-section'>
                <div className='Tearms'>
                  <span>Terms:</span>
                  <ul>
                    <li>{com_terms}</li>
                  </ul>
                </div>
                <div className='Reciver-sig'>
                  <div className='signature'>
                    <div className='sig-box'>
                    </div>
                    <p>Receiver Signature</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <button className="buttons" id="pdf" onClick={printDocument}>
        <span className="button-content">Download </span>
      </button>
      <button className="buttons" id="pdfprint" onClick={genretpdf}>
        <span className="button-content">Print</span>
      </button>
    </>
  );
}