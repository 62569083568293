import { Link } from 'react-router-dom';
import './Footer.css';
import Logo from "./images/logo.png";
import Appstore from "./images/appstore.png";
import Playstore from "./images/playstore.png";
import { Helmet } from 'react-helmet';

export default function footer() {
  const Tearms = () => {
    window.open("https://softnoesis.com/apps/invoice/terms_privacy_policy.php");
  }
  const ios = () => {
    window.open("https://apps.apple.com/app/invoice-maker-bill-generator/id1444998056");
  }
  const andriod = () => {
    window.open("https://play.google.com/store/apps/details?id=com.softnoesis.invoice");
  }
  const Facebook = () => {
    window.open("https://www.facebook.com/invoicegeneratbill");
  }
  const sendEmail = () => {
    window.open("mailto:support@billnama.com?subject=SendMail&body=Description");
  };
  return (
    <>
      {/* <footer>
        <div className='facebok'>
          <div className="facebook_link">
            <img src={Logo} alt="logo" />
            <p>Invoice your customers in seconds.</p>
            <i className="fa-brands fa-facebook" onClick={Facebook}></i>
          </div>
        </div>

        <div className='footer_dis'>
          <div className="use_invoice">
            <h2>Use Online Invoice</h2>
            <ul>
              <li onClick={sendEmail}> Feedback</li>
              <Link to="/subscription">
                <li>Subscription</li>
              </Link>
              
              <li></li>
            </ul>
          </div>


          <div className="education">
            <h2>Information</h2>
            <ul>
            <Link to="/privacy-statement" target="_blank">
              <li>Privacy Statement</li>
              </Link>
            <Link to="/terms-of-use" target="_blank">
              <li>Terms of Use</li>
              </Link>
            <Link to="/data-deletion-instruction" target="_blank">
              <li>Data Deletion Instruction</li>
              </Link>
            <Link to="/refund-policy" target="_blank">
              <li>Refund and cancelling</li>
              </Link>
            <Link to="/contact-us" target="_blank">
              <li>Contact us</li>
              </Link>
              
            </ul>
          </div>
          <div className="footer_mobile">
            <h2>Get Started</h2>
            <ul>
              <li><img src={Appstore} alt="appstore" onClick={ios} /></li>
              <li><img src={Playstore} alt='playstore' onClick={andriod} /></li>
            </ul>
          </div>
        </div>
      </footer> */}


      <footer>
        <p>© 2024  <Link to="https://billnama.com/" style={{ color: "#4c5fdf" }}>billnama.com</Link></p>

        <Helmet>
          <script
            type="text/javascript"
            src=" https://www.shakebug.com/assets/js/shakebug-min-1.0.js "
            async
            id="apikey"
            isreportingiconvisible="false"
            isallowshake="false"
            apikey="bBAL7ZTDMatWFuFPbZyLLjXhE8XgzZ"
            data-react-helmet="true">
          </script>
        </ Helmet>
      </footer>
    </>
  );
}