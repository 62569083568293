// Popup.js
import React from 'react';
import './inprogress.css'
const Popup = ({ message, onClose }) => {
  return (
    <div className="popup-overlay">
    <div className="popup-box">
      <h3 className="popup-title">{message}</h3>
      <button className="popup-close-btn" onClick={onClose}>
        Close
      </button>
    </div>
  </div>
  );
};

export default Popup;
