import { Link, useNavigate } from 'react-router-dom';
import './Subscription.css';
import Logo from "./images/logo.png";
import { Helmet } from 'react-helmet';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import AppIcon from '../Payment/images/app-icon.png';
import firebase, { child, database, get, storage, useFirebase, databaseurl } from '../Login/config';
import Paymentscreen from "./images/paymentscreen.png";


export default function Subscription() {
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [isExpired, setIsExpired] = useState(false); // New state for expiry check

    const UserID = localStorage.getItem("AccessUserId");
    const [subscriptionData, setSubscriptionData] = useState(null);
    let Type;

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    // Check if subscription has expired
    useEffect(() => {
        const checkSubscriptionExpiry = async () => {
            try {
                const response = await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/premium.json`);
                const data = await response.json();
                if (data && data.premiumLastDate) {
                    const premiumLastDate = new Date(data.premiumLastDate);
                    const currentDate = new Date();

                    if (premiumLastDate < currentDate) {
                        setIsExpired(true);  // Set expiry status
                    }
                }
            } catch (error) {
                console.error("Error fetching subscription data from Firebase:", error);
            }
        };

        checkSubscriptionExpiry();
    }, [UserID]);

    useEffect(() => {
        const loadRazorpayScript = () => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = "https://checkout.razorpay.com/v1/checkout.js";
                script.onload = () => {
                    resolve(true);
                };
                document.body.appendChild(script);
            });
        };
        loadRazorpayScript();
    }, []);

    const createSubscription = async (planId) => {
        const url = "https://payment-test-kappa.vercel.app/api/createSubscription";
        const data = {
            plan_id: planId,
            total_count: 6,
            quantity: 1,
        };

        try {
            const response = await axios.post(url, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const { subscriptionId } = response.data;
            setSubscriptionId(subscriptionId);
            return subscriptionId;
        } catch (error) {
            console.error("Error creating subscription:", error);
            return null;
        }
    };

    const handlePayment = (subscriptionId) => {
        if (!subscriptionId) {
            console.error("Subscription ID not available yet");
            return;
        }

        const options = {
            key: "rzp_test_O5YpNjI1WJIySR", // Replace with your Razorpay Key ID
            subscription_id: subscriptionId,
            name: "BillNama",
            description: "Monthly Test Plan",
            image: { AppIcon },
            callback_url: "https://app.billnama.com/payment-successfully",
            prefill: {
                name: "",
                email: "gaurav.kumar@example.com",
                contact: "+919429022319",
            },
            handler: function (response) {
                console.log("Payment successful!", response);
                handlePaymentSuccess(response);
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    const handlePaymentSuccess = async (response) => {
        const { razorpay_payment_id, razorpay_subscription_id, razorpay_signature } = response;

        try {
            // Delay the API call to fetch subscription details
            setTimeout(async () => {
                const apiUrl = "https://payment-test-kappa.vercel.app/api/getSubscription";
                const res = await axios.post(apiUrl, {
                    sub_id: razorpay_subscription_id,
                });

                console.log("Subscription Details:", res.data);

                // Store subscription data in state
                setSubscriptionData(res.data);

                if (res.data) {
                    const {
                        current_end: premiumLastDate, // last date of current subscription
                        id: subscriptionId,           // subscription id
                        current_start: paymentDate,    // date of the payment
                        current_end: lastDate          // last date of the plan
                    } = res.data;

                    // Fetch current transaction array from Firebase
                    const currentTransactionResponse = await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/premium/transactions.json`);
                    const currentTransactionArray = await currentTransactionResponse.json() || [];

                    // Fetch current premium data from Firebase
                    const currentPremiumResponse = await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/premium.json`);
                    const currentPremiumData = await currentPremiumResponse.json() || {};

                    const premiumLastDateString = String(premiumLastDate);
                    const paymentDateString = String(paymentDate);
                    const lastDateString = String(lastDate);
                    // Append new purchase data to the transaction array
                    const updatedTransactionArray = [
                        ...currentTransactionArray,
                        {
                            subscriptionId,   // new subscription id
                            paymentDate: paymentDateString,
                            planName: Type, // assuming monthly plan, adjust as needed
                            lastDate: lastDateString,
                            device: 'Website'
                        }
                    ];

                    // Create the updated premium data in the desired format
                    const updatedPremiumData = {

                        isLifeTimePremium: false,  // Set to false, adjust based on plan
                        premiumLastDate: premiumLastDateString,  // Convert to ISO string
                        subscriptionId,
                        device: 'Website',
                        transactions: updatedTransactionArray // Include the transaction array

                    };

                    // Send updated premium data to Firebase
                    await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/premium.json`, {
                        method: "PUT", // Use PUT to update the premium section
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(updatedPremiumData), // Upload the new structure
                    });

                    console.log("Updated premium data sent to Firebase successfully!");
                } else {
                    console.error("No subscription details found.");
                }
            }, 10000); // 10 seconds delay for fetching subscription details
        } catch (error) {
            console.error("Error fetching subscription details:", error);
        }
    };






    const handleSubscriptionAndPayment = async (planId,type) => {
        setIsLoading(true);
        localStorage.setItem("type",type);
        Type = localStorage.getItem("type");
        const subscriptionId = await createSubscription(planId);
        if (subscriptionId) {
            handlePayment(subscriptionId);
        }
        setIsLoading(false);
    };

    // const [isFlipped, setIsFlipped] = useState(false);

    // const handleFlip = () => {
    //     setIsFlipped(!isFlipped);
    // };


    const [isOpen, setIsOpen] = useState(false);
    const popupRef = useRef(null);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    // Handle outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);


    const navigate = useNavigate(); // Initialize the navigate function

    const handleGoBack = () => {
        navigate(-1); // This goes back to the previous page
    };
    return (
        <>
            <Helmet>
                <title>Subscription Plans | BillNama</title>
                <meta
                    name="description"
                    content="Meta Description: Explore our subscription plans tailored to fit your invoicing needs."
                />
                <link rel="canonical" href="https://billnama.com/subscription" />
            </Helmet>
            <div id="premium_box_popup">
                <div className="premium_box_popup_box">
                    <div className="premium_box_popup_logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="premium_box_popup_heading">
                        <h2>Plan & Pricing</h2>
                        <p>Explore our variety of plans designed to revolutionize your invoicing experience</p>
                    </div>
                    <div className="premium_box_popup_details">
                        <div className={`premium_box_popup_plan ${isFlipped ? 'flipped' : ''}`}>
                            <div class="btn-container">

                                <label class="switch btn-color-mode-switch" id="flip_button" onClick={handleFlip}>
                                    <input value="1" id="color_mode" name="color_mode" type="checkbox" />
                                    <label class="btn-color-mode-switch-inner" data-off="INR" data-on="USA" for="color_mode" ></label>
                                </label>
                            </div>
                            <div className="premium_box_inner">
                            <div className="premium_box_front">
                                    <h3>Select Your Plan</h3>
                                    {/* Month plan */}
                                    <div className="month_plan">
                                        <div className="month_plan_time">
                                            <label>Monthly</label>
                                        </div>
                                        <div className="month_plan_details">
                                            <h2>Rs.179 / One Month</h2>
                                            <p>Start with <span>3 days</span> trial</p>
                                        </div>
                                        <button onClick={() => handleSubscriptionAndPayment("plan_P49Nh8gVYB4WhK","month")}
                                            disabled={isLoading}
                                        >Get Now</button>
                                        {isLoading ? "" : ""}
                                    </div>
                                    {/* Year plan */}
                                    <div className="month_plan" id="year_plan">
                                        <div className="month_plan_time">
                                            <label>Yearly</label>
                                        </div>
                                        <div className="month_plan_details">
                                            <h2>Rs.1649 / One Year</h2>
                                            <p>Save upto <span> 35%</span></p>
                                        </div>
                                        <button onClick={() => handleSubscriptionAndPayment("plan_P49OUSq59c09S2","year")}
                                            disabled={isLoading}>Get Now</button>
                                            {isLoading ? "" : ""}
                                    </div>
                                    <Link to="/privacy-statement">
                                        <p className="palan_tearms">Terms and privacy policy</p>
                                    </Link>
                                </div>
                                <div className="premium_box_back" style={{ backfaceVisibility: "hidden !important" }}>
                                    <h3>Select Your Plan</h3>
                                    {/* Month plan */}
                                    <div className="month_plan">
                                        <div className="month_plan_time">
                                            <label>Monthly</label>
                                        </div>
                                        <div className="month_plan_details">
                                            <h2>$3.99 / One Month</h2>
                                            <p>Start with <span>3 days</span> trial</p>
                                        </div>
                                        <button onClick={openPopup}>Get Now</button>
                                    </div>
                                    {/* Year plan */}
                                    <div className="month_plan" id="year_plan">
                                        <div className="month_plan_time">
                                            <label>Yearly</label>
                                        </div>
                                        <div className="month_plan_details">
                                            <h2>$32.99 / One Year</h2>
                                            <p>$1.66/mo.<span> Save 35%</span></p>
                                        </div>
                                        <button onClick={openPopup}>Get Now</button>
                                    </div>
                                    <Link to="/privacy-statement">
                                        <p className="palan_tearms">Terms and privacy policy</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="premium_box_popup_benefits">
                            <table>
                                <thead>
                                    <tr className="top_row">
                                        <th><i className="fa-solid fa-star"></i> Unlock the Advantages</th>
                                        <th>Free</th>
                                        <th>Premium</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>No Advertisement</td>
                                        <td className="text_center"><i className="fa-solid fa-xmark"></i></td>
                                        <td className="text_center"><i className="fa-solid fa-check"></i></td>
                                    </tr>
                                    <tr>
                                        <td>No limitation on business registration</td>
                                        <td className="text_center"><i className="fa-solid fa-xmark"></i></td>
                                        <td className="text_center"><i className="fa-solid fa-check"></i></td>
                                    </tr>
                                    <tr>
                                        <td>No limitation on invoice</td>
                                        <td className="text_center"><i className="fa-solid fa-xmark"></i></td>
                                        <td className="text_center"><i className="fa-solid fa-check"></i></td>
                                    </tr>
                                    <tr>
                                        <td>No limitation on invoice preview</td>
                                        <td className="text_center"><i className="fa-solid fa-xmark"></i></td>
                                        <td className="text_center"><i className="fa-solid fa-check"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="premiuim_section_skip">
                        <button onClick={handleGoBack} style={{ backgroundColor: "transparent", border: "none", outline: "none", textDecoration: "underline", cursor: "pointer" }}>
                            <p>Skip the Professional Invoice!</p>
                        </button>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        overflowY: "auto",
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                    }}
                >
                    <div
                        id="USD_popup"
                        ref={popupRef}
                        style={{
                            backgroundColor: 'white',
                            padding: '50px',
                            borderRadius: '8px',
                            width: '80%',
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                            <div style={{ position: "absolute", right: "-30px", top: "-40px" }}>
                                <b style={{ fontSize: "25px", zIndex: "9999", color: "#041737", cursor: "pointer" }} onClick={closePopup}>✖</b>

                            </div>
                            <div style={{ textAlign: "left", width: "60%", paddingLeft: "90px" }}>
                                <h2 style={{ color: "#041737", fontSize: "30px" }}>Download Our App</h2>
                                <p style={{ color: "#B9BEC7", marginTop: "20px", lineHeight: "30px" }}>commerce through prospective manufactured products intrinsicly myocardinata team building methodologies rather than proactive vortals. Credibly deploy accurate infrastructures and 029 synergy. Competently foster multidisciplinary</p>
                                <div style={{ marginTop: "40px", display: "flex", alignItems: "center" }} >
                                    <Link to="https://apps.apple.com/us/app/billnama-invoice-maker-gst/id1444998056" target='_blank'>
                                        <button style={{ backgroundColor: "rgba(34,137,230,0.12)", padding: "13px 18px", border: "none", outline: "none", fontSize: "15px", marginRight: "10px", cursor: "pointer", color: "#2289E6", display: "flex", alignItems: "center" }}>
                                            <i class="fa-brands fa-apple" style={{ marginRight: "10px", fontSize: "25px" }}></i>
                                            <span>Download Now</span>
                                        </button>
                                    </Link>
                                    <Link to="https://play.google.com/store/apps/details?id=com.softnoesis.invoice" target='_blank'>
                                        <button style={{ backgroundColor: "rgba(174,103,209,0.12)", padding: "13px 18px", border: "none", outline: "none", fontSize: "15px", display: "flex", alignItems: "center", color: "#AE67D1", cursor: "pointer", }}>

                                            <i class="fa-brands fa-google-play" style={{ marginRight: "10px", fontSize: "20px" }}></i>
                                            <span>Download Now</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div style={{ width: "40%", textAlign: "center" }}>
                                <img src={Paymentscreen} style={{ width: "80%" }} />
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </>
    );
}
