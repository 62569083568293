import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Blogo from "./images/logo.png";
import Confetti from "react-confetti";
import "./Sidebar.css";

export default function Sidebar() {
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState(location.pathname);
    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth >= 1140);
    const sidebarRef = useRef(null);
    const PremiumDate = localStorage.getItem("PremiumDate");

    // Check if PremiumDate is a timestamp (a valid number)
    let premiumDate;
    
    if (!isNaN(PremiumDate)) {
        // If it is a number, treat it as a Unix timestamp
        premiumDate = new Date(PremiumDate * 1000);
    } else {
        // If it's not a number, treat it as a formatted date string
        const parts = PremiumDate.split('/'); // Assuming format is 'DD/MM/YYYY'
        if (parts.length === 3) {
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1; // Months are zero-indexed
            const year = parseInt(parts[2], 10);
            premiumDate = new Date(year, month, day);
        } else {
            // Handle cases where PremiumDate is invalid
            premiumDate = new Date(""); // Create an invalid date
        }
    }
    
    // Format the date if it's valid
    let formattedDate;
    
    if (!isNaN(premiumDate)) {
        // Extract the day, month, and year
        const day = String(premiumDate.getDate()).padStart(2, '0'); // Add leading zero if needed
        const month = String(premiumDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = premiumDate.getFullYear();
        formattedDate = `${day}/${month}/${year}`;
    } else {
        // Default value if premiumDate is invalid
        formattedDate = "Invalid Date";
    }
    
    console.log("formattedDate", formattedDate);
    

    // Confetti animation state
    const [showConfetti, setShowConfetti] = useState(false);
    const [confettiOpacity, setConfettiOpacity] = useState(0);

    const handleItemClick = (path) => {
        setSelectedItem(path);
    };

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1140) {
                setIsSidebarVisible(true);
            } else {
                setIsSidebarVisible(false);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (window.innerWidth < 1141 && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsSidebarVisible(false);
            }
        };

        if (isSidebarVisible && window.innerWidth < 1141) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isSidebarVisible]);

    // Handle the confetti animation with a smooth transition
    const handleConfettiClick = () => {
        setShowConfetti(true);
        setConfettiOpacity(1); // Fade-in effect

        setTimeout(() => {
            setConfettiOpacity(0); // Start fade-out after 2.5 seconds
            setTimeout(() => {
                setShowConfetti(false); // Hide confetti after fade-out
            }, 500); // Wait for the fade-out to complete
        }, 2500); // Confetti shows for 2.5 seconds
    };

    return (
        <>
            <div ref={sidebarRef} className={`sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`} style={{ zIndex: "1100" }}>
                <div className="logo">
                    <Link to="/dashboard">
                        <img src={Blogo} alt="Logo" />
                    </Link>
                </div>
                <ul id="myDIV">
                    <Link to="/dashboard">
                        <li
                            className={selectedItem === "/dashboard" ? "selectedItem active" : "selectedItem"}
                            onClick={() => handleItemClick("/dashboard")}
                        >
                            <i className="fa-solid fa-house"></i>
                            <span>Dashboard</span>
                        </li>
                    </Link>
                    <Link to="/invoices">
                        <li
                            className={selectedItem === "/invoices" ? "sidebtn active" : "sidebtn"}
                            onClick={() => handleItemClick("/invoices")}
                        >
                            <i className="fa-solid fa-file-invoice"></i>
                            <span>Invoices</span>
                        </li>
                    </Link>
                    <Link to="/listed-business">
                        <li
                            className={selectedItem === "/listed-business" ? "sidebtn active" : "sidebtn"}
                            onClick={() => handleItemClick("/listed-business")}
                        >
                            <i className="fa-solid fa-circle-half-stroke"></i>
                            <span>Business</span>
                        </li>
                    </Link>
                    <Link to="/customers">
                        <li
                            className={selectedItem === "/customers" ? "sidebtn active" : "sidebtn"}
                            onClick={() => handleItemClick("/customers")}
                        >
                            <i className="fa-solid fa-address-book"></i>
                            <span>Customers</span>
                        </li>
                    </Link>
                    <Link to="/sold-products">
                        <li
                            className={selectedItem === "/sold-products" ? "sidebtn active" : "sidebtn"}
                            onClick={() => handleItemClick("/sold-products")}
                        >
                            <i className="fa-solid fa-file-contract"></i>
                            <span>Sold Items</span>
                        </li>
                    </Link>
                    <Link to="/currency">
                        <li
                            className={selectedItem === "/currency" ? "sidebtn active" : "sidebtn"}
                            onClick={() => handleItemClick("/currency")}
                        >
                            <i className="fa-solid fa-coins"></i>
                            <span>Currency</span>
                        </li>
                    </Link>

                    {!formattedDate ? (
                        <Link to="/subscription">
                            <li id="premium_button">
                                <button className="button" id="premium-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
                                        <path d="m18 0 8 12 10-8-4 20H4L0 4l10 8 8-12z"></path>
                                    </svg>
                                    Unlock pro
                                </button>
                            </li>
                        </Link>
                    ) : (
                        <div className="notifications-container" style={{ marginTop: "20px" }} onClick={handleConfettiClick}>
                            <div className="success">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="succes-svg">
                                            <path clipRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" fillRule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <div className="success-prompt-wrap">
                                        <p className="success-prompt-heading">Premium Access</p>
                                        <span>Valid Until</span>
                                        <div className="success-button-main">
                                            <p>{formattedDate}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ul>
            </div>

            {showConfetti && (
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    numberOfPieces={500}
                    style={{
                        opacity: confettiOpacity, // Use opacity for fade-in/out
                        transition: "opacity 0.5s ease",


                    }}
                />
            )}
            <button
                className="toggle-button"
                onClick={toggleSidebar}
                style={{
                    display: window.innerWidth < 1141 ? "block" : "none",
                    zIndex: "1200",
                    marginTop: "18px",
                    position: "fixed",
                    top: "0px",
                    left: "15px",
                    border: "none",
                    outline: "none",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "transparent",
                }}
            >
                <i className="fa-solid fa-bars" style={{ fontSize: "20px" }}></i>
            </button>
        </>
    );
}
