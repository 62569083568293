import { Link, redirect, useNavigate } from "react-router-dom";
import * as React from 'react';
import "./dashbord.css";
import Sidebar from "../Sidebar/Sidebar";
import firebase, { database, get, child, remove, getDatabase } from '../Login/config';
import { useEffect, useState, useRef } from "react";
import Adminlogo from '../Sidebar/images/360_F_481005079_HYTOGwBipvktrphdgtyqbeKuQjjfOpyf-removebg-preview.png'
import { doc } from "firebase/firestore";
// NavBar menu import code start..
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
// NavBar menu import code end..

// Session data show start
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Delete, Mail, Translate } from "@mui/icons-material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorageIcon from '@mui/icons-material/Storage';
// Session data show End

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DashHeader() {
  const Uid = localStorage.getItem("AccessUserId");
  const UserName = localStorage.getItem("UserName");

  let FirstLater = '';

  if (UserName) {
    FirstLater = UserName.substring(0, 1);
  } else {
    // console.warn('UserName is null');
    // Optionally, set a default value for FirstLetter
    FirstLater = ''; // Or any other default value you prefer
  }

  // Now you can safely use FirstLetter


  const opens = () => {
    document.getElementById("menu_sidebar").style.display = "block";
  }
  const close = () => {
    document.getElementById("menu_sidebar").style.display = "none";
  }
  // const sendEmail = () => {
  //   window.open("mailto:support@billnama.com?subject=SendMail&body=Description");
  // };

  // const Facebook = () => {
  //   window.open("https://www.facebook.com/invoicegeneratbill");
  // }
  // const Tearms = () => {
  //   window.open("https://softnoesis.com/apps/invoice/terms_privacy_policy.php");
  // }
  useEffect(() => {
    setUser(localStorage.getItem("email"));
  });
  const [show, setShow] = useState();
  const [user, setUser] = useState();
  const handallogout = () => {
    localStorage.clear("email");
    // redirect("/login");
    window.location = "/login"
  }

  // NavBar menu code start....

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // NavBar menu code end....

  const [allcompanies, setAllCompany] = useState([]);
  const [companies, setCompany] = useState([]);
  const [session, setSession] = useState([]);
  const [premiumuser, setPremiumUser] = useState([]);
  const [allpremiumuser, setAllPremiumUser] = useState();
  const [companie, setCompanies] = useState([]);
  const [CompanyId, setCompanyId] = useState("");

  let companydata;
  let sessiondata;
  let premium;
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState();
  const getcompany = async () => {
    await get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        companydata = snapshot.val().company;
        sessiondata = snapshot.val().session;
        const sessionarray = Object.keys(sessiondata).map(key => ({ [key]: sessiondata[key] }));
        setSession(sessionarray);

        setAllCompany(companydata);
        premium = snapshot.val().premium;
        const companyarray = Object.keys(companydata).map(key => ({ [key]: companydata[key] }));
        setCompanies(companyarray);
        // console.log('premium', premium);
        setCompany(Object.entries(data.company));
        console.log()
        const premiumarray = Object.keys(premium).map(key => ({ [key]: premium[key] }));
        console.log("premiumarray", premiumarray);

        // Use the find method to get the object that contains premiumLastDate
        const premiumLastDateObject = premiumarray.find(obj => obj.premiumLastDate);
        const premiumLastDate = premiumLastDateObject ? premiumLastDateObject.premiumLastDate : null;

        // console.log("premiumLastDate", premiumLastDate);

        setAllPremiumUser(premiumarray);
        setPremiumUser(premiumLastDate);
        localStorage.setItem("PremiumDate", premiumLastDate);

        companies.forEach(result => {
          setCompanyId(result[1].companyId);
        })
      }
    }).catch((error) => {
      console.error(error);
    });

    // console.log('premiumuser', premiumuser)
  };
  // Premium dat show start




  // navigate to form start
  const UserId = localStorage.getItem("AccessUserId");
  const changedateformate = premiumuser.slice(0, 2);
  const changemonthformate = premiumuser.slice(3, 5);
  const changeyearformate = premiumuser.slice(6, 10);
  const premiumdatechange = changemonthformate + "/" + changedateformate + "/" + changeyearformate;


  const dates = new Date(premiumdatechange) > new Date();
  const navigateToforms = () => {
    if (UserId) {
      if (companies && companies.length > 0) {
        navigate('/generate-invoice');
      } else {
        navigate('/list-your-business');
      }
    }else if(!UserId){
      if (companies && companies.length > 0) {
        navigate('/generate-invoice');
      } else {
        navigate('/list-your-business');
      }
    }
     else {
      navigate('/login');
    }
  };

  useEffect(() => {
    getcompany();
    // console.log('testdata', session)
  }, []);


  // session data poppups start
  const [view, setView] = React.useState(false);

  const handleClickview = () => {
    setView(true);
  };

  const handleCloses = () => {
    setView(false);
  };
  // session data poppups End


  // Payment history data popups start
  const [isVisible, setIsVisible] = React.useState(false);

  const openPopup = () => {
    setIsVisible(true);
  };

  const closePopup = () => {
    setIsVisible(false);
  };
  // Payment history data popups End



  // session date format code start here...

  // Define formatDate function here
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      return "Invalid Date"; // Handle the invalid date case
    }

    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    };

    const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(date);
    return formattedDate;
  };
  const Premium_open = () => {
    document.getElementById("premium_box_popup").style.display = "block";
  };
  const PremiumDate = localStorage.getItem("PremiumDate");
  // show mail id in account setting start
  const Emailshow = localStorage.getItem("email");
  // show mail id in account setting End

  const mailtoLink = `mailto:support@billnama.com?subject=Account deletion request for user%0D%0A${Uid}&body=Hello,%0D%0A%0D%0A%0D%0APlease delete my account from BillNama having following details%0D%0A%0D%0AUserId: ${Uid}%0D%0AEmail: ${Emailshow}%0D%0AReason: <Write Here your Reason>%0D%0A%0D%0A%0D%0ABest%0D%0A%0D%0A%0D%0ASent from my iPhone`
  return (
    <>
      <div className="header" style={{ zIndex: "999" }} >


        <div className="menu">
          <p style={{ visibility: "hidden" }}>
            <i className="fa-solid fa-bars"></i>
          </p>
          <ul>

            {/* <li onClick={navigateToforms}>Create Invoice <i className="fa-solid fa-plus"></i></li> */}
            <li>
              <button class="continue-application" onClick={navigateToforms}>
                <div>
                  <div class="pencil"></div>
                  <div class="folder">
                    <div class="top">
                      <svg viewBox="0 0 24 27">
                        <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                      </svg>
                    </div>
                    <div class="paper"></div>
                  </div>
                </div>
                Create Invoice
              </button>


            </li>
            {/* <button  onClick={navigateToforms}> </button> */}
            {/* <li onClick={handleClickview} variant="outlined">Sessions</li> */}
            {/* session mui dilogbox  start*/}
            <React.Fragment>
              <Dialog
                fullScreen
                open={view}
                onClose={handleCloses}
                TransitionComponent={Transition}
              >
                <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleCloses}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sessions
                    </Typography>

                  </Toolbar>
                </AppBar>
                {session ? session.map((sessionData, index) => (

                  Object.entries(sessionData).map(([sessionId, sessionDetails]) => (

                    <div >
                      <List key={sessionId} >
                        <ListItemButton>
                          {/* <ListItemText primary={`Device Name: ${sessionDetails.deviceName} ${sessionDetails.browserName}`} secondary={`Login at ${formatDate(sessionDetails.createdDate)}`} tertiary={`For ID  ${Uid}`} />
                          <ListItemText primary={`For ID  ${Uid}`} /> */}
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                <Typography><b>{sessionDetails.deviceName}:</b> {sessionDetails.browserName}</Typography>
                                <Typography><b>Login at:</b> {formatDate(sessionDetails.createdDate)}</Typography>
                                <Typography><b>For ID:</b> {Uid}</Typography>
                              </>
                            }
                          />
                        </ListItemButton>
                        <Divider />
                      </List>
                    </div>

                  ))
                )) : ""}

              </Dialog>
            </React.Fragment>
            {/* session mui dilogbox  End*/}


            {/* payment History start */}
            <React.Fragment>
              <Dialog
                fullScreen
                open={isVisible}
                onClose={closePopup}
                TransitionComponent={Transition}
              >
                <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={closePopup}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Payment History
                    </Typography>

                  </Toolbar>
                </AppBar>


                <div >
                  <List  >
                    <ListItemButton>
                      <ListItemText
                        disableTypography
                        primary={
                          <>
                            <Typography><b>Data Not Found</b></Typography>

                          </>
                        }
                      />
                    </ListItemButton>
                    <Divider />
                  </List>
                </div>

              </Dialog>
            </React.Fragment>
            {/* payment History End */}




            {/* stting mui box start */}
            {/* <li>Settings</li> */}

            {/* stting mui box End */}

          </ul>
        </div>
        <div>
          <React.Fragment>
            {localStorage.getItem("AccessUserId") ? (
              <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar className="firstlater" sx={{ width: 32, height: 32 }}>
                      {Emailshow ? Emailshow.charAt(0).toUpperCase() : ''}
                    </Avatar>
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleClose} style={{ color: "gray" }}>
                    <ListItemIcon>
                      <Mail fontSize="small" style={{ color: "rgb(255,162,0)" }} />
                    </ListItemIcon>
                    {Emailshow || "Guest user"}
                  </MenuItem>
                  <MenuItem onClick={handleClose} style={{ color: "gray" }}>
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    <Link to={mailtoLink} target="_blank" style={{ color: "gray" }}>
                      Delete my account
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClickview} style={{ color: "gray" }}>
                    <ListItemIcon>
                      <StorageIcon fontSize="small" style={{ color: "#02151B" }} />
                    </ListItemIcon>
                    Sessions
                  </MenuItem>
                  <MenuItem onClick={openPopup} style={{ color: "gray" }}>
                    <ListItemIcon>
                      <AccountBalanceIcon fontSize="small" style={{ color: "#041737" }} />
                    </ListItemIcon>
                    Payment history
                  </MenuItem>
                  <MenuItem onClick={handallogout} style={{ color: "gray" }}>
                    <ListItemIcon>
                      <Logout fontSize="small" style={{ color: "#E63E32" }} />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Link to="/login">
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "550",
                    color: "#3963AD", // Change color to fit your design
                    // Optional: gives it a link-like appearance
                  }}
                >
                  Login
                </span>
              </Link>
            )}
          </React.Fragment>
        </div>
      </div>
    </>
  )
}