import "./bussiness.css"
import React, { useEffect, useState, useMemo } from 'react';
import firebase, { database, get, child, storage, useFirebase, imgDb, app, databaseurl } from '../Login/config';
import { update } from "firebase/database";
import DatanotFound from "./images/rMSD8h3gzM.gif";
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "../Dashbord/dashheader";
import Loader from '../loader/index';
import Invoiceheaderiimage from "../Invoicedata/images/hading_image.png";
import Footer from "../Footer/Footer";

// Add popup box code start
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { getDownloadURL, uploadBytes, uploadBytesResumable, ref } from 'firebase/storage';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from "react-helmet";

// Add popup box code end

export default function Bussiness() {

    const companyName = localStorage.getItem("companyName");
    const UserId = localStorage.getItem("AccessUserId");
    const Logo = localStorage.getItem("companyPhoto");
    var ProductData = JSON.parse(localStorage.getItem("ProductData"));

    // Start code getdata using firbase.

    const [company, setCompany] = useState();
    let bildata = [];
    let companydata = [];
    let updatedata = [];
    const [CompanyId, setCompanyId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [allcompanies, setAllCompany] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [premiumuser, setPremiumUser] = useState([]);
    const [allpremiumuser, setAllPremiumUser] = useState();
    const [forceUpdate, setForceUpdate] = useState(false);
    // const UserId = localStorage.getItem("AccessUserId");
    let companydatas;
    let premium;

    // Get Company data code start...
    const getcompany = () => {
        get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
            setIsLoading(true);
            setIsLoading(false);

            if (snapshot.exists()) {
                bildata = snapshot.val().billInvoice;
                const data = snapshot.val();
                companydata = snapshot.val().company;
                companydatas = snapshot.val().company;
                setAllCompany(companydata)
                premium = snapshot.val().premium;
                const companyarray = Object.keys(companydata).map(key => ({ [key]: companydata[key] }));
                setCompanies(companyarray);
                // console.log('premium', premium);
                setCompany(Object.entries(data.company));
                console.log()
                // const premiumarray = Object.keys(premium).map(key => ({ [key]: premium[key] }))
                // const premiumLastDates = premiumarray[1].premiumLastDate;

                const premiumarray = Object.keys(premium).map(key => ({ [key]: premium[key] }));
                console.log("premiumarray", premiumarray);

                // Use the find method to get the object that contains premiumLastDate
                const premiumLastDateObject = premiumarray.find(obj => obj.premiumLastDate);
                const premiumLastDate = premiumLastDateObject ? premiumLastDateObject.premiumLastDate : null;

                console.log("premiumLastDate", premiumLastDate);


                setAllPremiumUser(premiumarray);
                setPremiumUser(premiumLastDate);

                company.forEach(result => {
                    setCompanyId(result[1].companyId);
                })
            }
        }).catch((error) => {
            console.error(error);
        });
    };
    // Get Company data code end....

    // Update company firebase data code start....
    const [company_name, setComName] = useState("");
    const [terms, setTerms] = useState("");
    const [company_gst, setCompanyGst] = useState("");
    const [company_address, setCompanyAddress] = useState("");
    const [company_image, setCompanyimage] = useState();

    const [showdata, setShowData] = useState(false);
    const [firebasecompanyid, setFirebaseCompanyId] = useState("");
    const [refreshData, setRefreshData] = useState(false);

    // Update company firebase data code end.....
    const close = () => {
        document.getElementById('popups').style.display = "none";
        document.getElementById('popups').style.transition = "all 1s";
    }
    const updates = (CompanyId) => {
        localStorage.setItem("Update_Company_Id", CompanyId);
        document.getElementById('popups').style.display = "block";
        document.getElementById('popups').style.transition = "all 1s";
        setFirebaseCompanyId(CompanyId);
    }
    const UpdateCompanyId = localStorage.getItem("Update_Company_Id");

    const updatecompanies = () => {
        get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val().company;
                Object.keys(data).forEach(function (val) {
                    if (val == UpdateCompanyId) {
                        updatedata = data[val];
                        setComName(updatedata.companyName);
                        setTerms(updatedata.terms);
                        setCompanyGst(updatedata.gstNumber);
                        setCompanyAddress(updatedata.address);
                        setCompanyimage(updatedata.companyPhoto);
                        console.log('updatedata.companyPhoto', updatedata.companyPhoto)
                    }
                });
            }
        }).catch((error) => {
            console.error(error);
        });
    };


    // Update image code start here...

    const [selectedImageURL, setSelectedImageURL] = useState(null);
    const [oldImageURL, setOldImageURL] = useState();
    const [imageFile, setImageFile] = useState(null);

    // Function to handle image upload
    async function handleUpload(e) {
        const file = e.target.files[0];
        const storageRef = ref(storage, `invoiceGenerator/users/${UserId}/${CompanyId}/companyPhoto/${file.name}`);
        try {
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            setSelectedImageURL(downloadURL);
            setImageFile(file); // Update imageFile state with the uploaded file
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    }

    // Function to upload image to Firebase Storage
    async function uploadImage(file) {
        const imgRef = ref(imgDb, `invoiceGenerator/users/${UserID}/${uuidv4()}`);
        await uploadBytes(imgRef, file);
    }

    // Function to fetch old image URL and set it to state
    const fetchOldImageURL = () => {
        // Fetch old image URL based on company ID
        const companyRef = ref(storage, `/invoiceGenerator/users/${UserId}/`);
        getDownloadURL(companyRef)
            .then((url) => {
                setOldImageURL(url); // Set the old image URL to the state variable
            })
            .catch((error) => {
                console.error("Error fetching old image URL:", error);
                // Handle error
            });
    };

    useEffect(() => {
        fetchOldImageURL();
    }, [CompanyId]); // Fetch old image URL when component mounts


    // Update image code end here....

    // Update data code start..........
    const handleSubmitChange = async () => {
        // Check if imageFile or selectedImageURL is defined
        if (imageFile || selectedImageURL) {
            // Upload new image if imageFile exists
            if (imageFile) {
                try {
                    await uploadImage(imageFile);
                } catch (error) {
                    console.error("Error uploading image:", error);
                    // Handle error
                }
            }

            // Update other data including companyPhoto
            update(child(database, `invoiceGenerator/users/${UserId}/company/${firebasecompanyid}/`), {
                companyName: company_name,
                terms: terms,
                gstNumber: company_gst,
                address: company_address,
                companyPhoto: imageFile ? selectedImageURL : company_image // Use selected image URL if new image is selected, otherwise use existing company image
            });
        } else {
            // If neither imageFile nor selectedImageURL is defined, update other data excluding companyPhoto
            update(child(database, `invoiceGenerator/users/${UserId}/company/${firebasecompanyid}/`), {
                companyName: company_name,
                terms: terms,
                gstNumber: company_gst,
                address: company_address
            });
        }
        setForceUpdate(prevState => !prevState);
    };

    useEffect(() => {
        getcompany();
        updatecompanies();
    }, [showdata, refreshData, forceUpdate]);
    // Update data code end............

    // confirmation popup box code start

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // confirmation popup box code end

    // End code getdata using firbase.

    // const companyPhoto = localStorage.getItem("companyPhoto");
    const UserID = localStorage.getItem("AccessUserId");

    // const changedateformate = premiumuser.slice(0, 2);
    // const changemonthformate = premiumuser.slice(3, 5);
    // const changeyearformate = premiumuser.slice(6, 10);
    // const premiumdatechange = changemonthformate + "/" + changedateformate + "/" + changeyearformate;
    // console.log(premiumuser,"premiumdatechange");

    const navigate = useNavigate();

    const dates = new Date(premiumuser) > new Date();
    const navigateToform = () => {
        if (UserId) {
            if (UserId) {

                if (companies && companies.length > 0) {

                    if (premiumuser) {

                        navigate('/list-your-business');
                    }
                    else {
                        navigate('/subscription');
                    }
                }
                else {
                    navigate('/list-your-business');
                }
            } else {
                navigate('/login');
            }
        }
        else {
            navigate('/list-your-business');
        }

    }

    // Apply tax code start
    const [Taxs, setTax] = useState('');
    let Symbol = localStorage.getItem("symbol");
    let CurrencyCode = localStorage.getItem("currencyCode");
    const TaxType = () => {
        if (CurrencyCode == "EUR" && CurrencyCode == "CZK" && CurrencyCode == "DKK" && CurrencyCode == "HUF" && CurrencyCode == "PLN" && CurrencyCode == "RON" && CurrencyCode == "SEK" && CurrencyCode == "GBP") {
            setTax("VAT");
            localStorage.setItem("tax", Taxs);

        } else if (CurrencyCode == "INR") {
            setTax("GST");
            localStorage.setItem("tax", Taxs);
        }
        else {
            setTax("TAX");
            localStorage.setItem("tax", Taxs);
        }
    }
    useEffect(() => {
        TaxType();
    }, [Taxs]);
    const [taxvalue, settaxvalue] = useState("");
    const handleClick = (value) => {
        settaxvalue(value);
    };
    const [discount, setdiscount] = useState("");
    const handleClicks = (value) => {
        setdiscount(value);
    };
    // Apply tax code end\
    const Customeplaceholder = "Enter" + " " + Taxs;


    // Start local storage code here................

    const localBusinessData = JSON.parse(localStorage.getItem('business'));

    // End local storage code here..................

    return (
        <>
            <Helmet>
                <title>
                    Hassle-free Manage Your Listed Business Details
                </title>
                {/* <meta
          name="description"
          content="Explore the Invoice mobile app case study by Softnoesis, a testament to our success in mobile development. Discover how we created a user-friendly invoicing solution, enhanced productivity, and streamlined business operations. Click now to dive into this transformative success story!"
        /> */}
                <link
                    rel="canonical"
                    href="https://app.billnama.com/listed-business"
                />
            </Helmet>
            <div className="dashbord">
                <div className="space">

                </div>
                {/* Sidebar code  start*/}
                <Sidebar />
                {/* Sidebar code  end*/}
                <div className="account_details">
                    <DashHeader />
                    <div className="invoice_info" id="invoice_info">
                        <div className="invoices_details">
                            <div className="invoices_head">
                                <div>
                                    <h2>Business  </h2>
                                    <ul>
                                        {/* <li>Dashbord</li>
                                        <li>Business</li> */}
                                        <li id="create_business"><button onClick={navigateToform}>Create New Business  <i className="fa-solid fa-plus"></i></button></li>
                                    </ul>
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                        <div id="main_section_business_invoice" style={{ position: "relative", }}>
                            <div className="main_section" id="main_section_business" style={{ padding: "0px" }}>
                                <div className="bussiness_section_heading" id="bussiness_section_heading" style={{ position: "sticky", top: "0px", width: "100%", left: "0px", padding: "15px", backgroundColor: "#3963AD", color: "white", borderRadius: "7px 7px 0px 0px", zIndex: "999", fontSize: "14px" }}>
                                    <p>Business Logo</p>
                                    <p>Business Name</p>
                                    <p>{Taxs} Number</p>
                                    <p>Address</p>
                                    <p>Action</p>
                                </div>

                                <div style={{ padding: "0px 15px" }}>
                                    {isLoading ? <Loader /> :
                                        UserId &&
                                        <div>
                                            <div className='bussiness' >
                                                <div>
                                                    {/* Company details code start  */}
                                                    {company ? company.map((company_data, i) => {
                                                        return (
                                                            <>
                                                                <div className="Compny">
                                                                    <div className="Compny_info">
                                                                        <div className="compny_left">
                                                                            <div className='compny_details_logo'>
                                                                                <img src={company_data[1].
                                                                                    companyPhoto} />

                                                                            </div>
                                                                            <div className='compny_info_details'>
                                                                                <p>{company_data[1].companyName}</p>
                                                                            </div>
                                                                            <div >
                                                                                <p>{company_data[1].gstNumber || <i className="fa-solid fa-minus"></i>}</p>
                                                                            </div>
                                                                            <div >
                                                                                <p>{company_data[1].address || <i className="fa-solid fa-minus"></i>}</p>
                                                                            </div>
                                                                            <div className="compny_info_update">
                                                                                {/* <button id="edit" onClick={() => { setShowData(!showdata); updates(company_data[0]) }} value={company_data[0]}>
                                                                                <i className="fa-regular fa-pen-to-square"></i>
                                                                            </button> */}
                                                                                <button class="edit-button" onClick={() => { setShowData(!showdata); updates(company_data[0]) }} value={company_data[0]}>
                                                                                    <svg class="edit-svgIcon" viewBox="0 0 512 512">
                                                                                        <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                                                                                    </svg>
                                                                                </button>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }) :
                                                        <div className="Compny">
                                                            <div className="Compny_info">
                                                                <div className="compny_left">
                                                                    <div className='compny_details_logo'>
                                                                        <img src={localBusinessData.
                                                                            companyPhoto} />

                                                                    </div>
                                                                    <div className='compny_info_details'>
                                                                        <p>{localBusinessData[1].companyName}</p>
                                                                    </div>
                                                                    <div >
                                                                        <p>{localBusinessData[1].gstNumber || <i className="fa-solid fa-minus"></i>}</p>
                                                                    </div>
                                                                    <div >
                                                                        <p>{localBusinessData[1].address || <i className="fa-solid fa-minus"></i>}</p>
                                                                    </div>
                                                                    <div className="compny_info_update">
                                                                        {/* <button id="edit" onClick={() => { setShowData(!showdata); updates(localBusinessData[0]) }} value={localBusinessData[0]}>
                                                                <i className="fa-regular fa-pen-to-square"></i>
                                                            </button> */}
                                                                        <button class="edit-button" onClick={() => { setShowData(!showdata); updates(localBusinessData[0]) }} value={localBusinessData[0]}>
                                                                            <svg class="edit-svgIcon" viewBox="0 0 512 512">
                                                                                <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                                                                            </svg>
                                                                        </button>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        // <div id="datanot_found">
                                                        //     <img src={DatanotFound} ></img>
                                                        // </div>
                                                    }
                                                    {/* Company details code End  */}
                                                </div>
                                            </div>
                                        </div>
                                        || Logo && companyName &&
                                        <div className='bussiness' >
                                            <div>
                                                <div className="Compny">
                                                    <div className='Compny_info'>
                                                        <div className="compny_left">
                                                            <div className='compny_details_logo'>
                                                                <img src={Logo} />
                                                            </div>
                                                            <div className='compny_info_details'>
                                                                <h3>{companyName}</h3>
                                                            </div>
                                                            <div className="compny_info_update">
                                                                <button onClick={updates}>
                                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        || <div id="datanot_found">
                                            <img src={DatanotFound} ></img>
                                        </div>}
                                </div>
                                {/* Update popup HTML code start */}
                                <div className="popups" id="popups">
                                    <div className="details">
                                        {/* <div id="cancele" >
                                        <p ><i className="fa-solid fa-xmark" onClick={close}></i></p>
                                    </div> */}
                                        <div className="field">
                                            <div>
                                                <h2>Business Edit</h2>
                                                <div className="compny_logo">
                                                    <div className='logoprivew' id="logoprivew">
                                                        {selectedImageURL ? (
                                                            <img src={selectedImageURL} alt="Selected Image" id='update_preview' />
                                                        ) : (
                                                            <img src={company_image} alt="Old Image" id='update_preview' />
                                                        )}
                                                    </div>

                                                    <label for='file'><i className="fa-regular fa-file"></i> Logo</label>
                                                    <input type="file" id="file" name="companyPhoto" onChange={(e) => handleUpload(e)} />

                                                </div>
                                                <div className="compny_name">
                                                    <label>Name</label>
                                                    <input placeholder="Business Name" name="companyName" value={company_name} onChange={(e) => { setComName(e.target.value) }} />
                                                </div>
                                                <div className="compny_gst">
                                                    <label>{Taxs}</label>
                                                    <input placeholder={Customeplaceholder} name="gstNumber" value={company_gst} onChange={(e) => { setCompanyGst(e.target.value) }} />
                                                </div>
                                                <div className="compny_address">
                                                    <label>Address</label>
                                                    <textarea placeholder="Business Address" name="address" value={company_address} onChange={(e) => { setCompanyAddress(e.target.value) }} >
                                                    </textarea>
                                                </div>
                                                <div className="compny_terms">
                                                    <label>T&C</label>
                                                    <textarea placeholder="Business T&C" name="terms" value={terms} onChange={(e) => { setTerms(e.target.value) }} >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn">
                                            <button onClick={() => { handleClickOpen(); close() }}>Update</button>
                                            <button onClick={close}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                {/* Update popup HTML code End */}
                                <React.Fragment >
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">
                                        <DialogContent style={{ textAlign: "center" }}>
                                            <DialogContentText id="alert-dialog-description">
                                                <p ><i className="fa-solid fa-circle-exclamation" style={{ color: "red" }}></i></p>
                                                <h2>Update Business</h2>
                                                <span>Are you sure you want to update your data.</span>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions id="update_btn">
                                            <Button onClick={handleClose}>Cancel</Button>
                                            <Button onClick={() => { handleSubmitChange(); setRefreshData(!refreshData); handleClose() }} autoFocus>
                                                Update
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className="allrights_recives">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}